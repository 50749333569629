export const KYC = 'api/users/kyc';
export const LOGIN_USER_ACCOUNT = 'oauth/token';

export const GET_ALL_USER = 'api/users';
export const GENERATE_USER_CONTRACT = 'api/users/pdf';
export const CREATE_USER_ACCOUNT = 'api/users/create';
export const UPDATE_USER_ACCOUNT = 'api/users/profile';
export const GET_USER_INTERESTS = 'api/users/interests';
export const UPDATE_USER_PASSWORD = 'api/users/password';
export const GET_USER_PUBLIC_DATAS = 'api/users/published';
export const UPDATE_USER_INTERESTS = 'api/users/interests';
export const UPDATE_USER_PUBLIC_DATAS = 'api/users/published';
export const GET_USER_BANK_ACCOUNT = 'api/users/bank-account';
export const UPDATE_USER_BANK_ACCOUNT = 'api/users/bank-account';

export const GET_ACTIVE_PLAN = 'api/users/plan';
export const UNSUBSCRIBE = 'api/users/unsubscribe';
export const GET_TRANSACTIONS = 'api/users/transactions';
export const GET_SUBSCRIPTIONS = 'api/users/subscriptions';
export const GET_USER_CONTACTS = 'api/contacts';

export const SEND_RESET_PASSWORD_CODE = "api/users/reset-password-mail";
export const RESET_PASSWORD = "api/users/reset-password";
