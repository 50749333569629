import React, { lazy, Suspense } from "react";
import { AUTH } from '../../constants/FrontendUrl';
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={AUTH.LOGIN} component={lazy(() => import(`./authentication/login`))} />
        <Route path={AUTH.REGISTRATION} component={lazy(() => import(`./authentication/register`))} />
        <Route path={AUTH.RESET_PASSWORD} component={lazy(() => import(`./authentication/reset-password`))} />
        <Route path={AUTH.FORGOT_PASSWORD} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={AUTH.THANK_YOU} component={lazy(() => import(`./authentication/thank-you`))} />
        <Redirect to={AUTH.LOGIN} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

