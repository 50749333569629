import React from 'react'
import Utils from 'utils';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ChatService from 'services/chat';
import Flex from 'components/shared-components/Flex';
import { Scrollbars } from 'react-custom-scrollbars';
import { Avatar, Divider, Input, Form, Button, Menu } from 'antd';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { FileOutlined, SendOutlined } from '@ant-design/icons';

const menu = (props) => {
	return (
		<Menu>
			<Menu.Divider />
			<Menu.Item key="3" onClick={() => props.resetCurrentChat()}>
				<span>Close Chat</span>
			</Menu.Item>
		</Menu>
	);
}

export class Conversation extends React.Component {
	formRef = React.createRef();
	chatBodyRef = React.createRef();

	state = {
		info: {},
		messages: [],
	}

	sessionToken = (Object.fromEntries(new URLSearchParams(window.location.search)).q) ? Object.fromEntries(new URLSearchParams(window.location.search)).q : null;


	componentDidMount() {
		setTimeout(() => {
			this.setState({ info: this.props.session });
			//this.setState({ messages: this.props.messages });
		}, 1000);
		setInterval(() => {
			this.findMessages(this.sessionToken);
		}, 2000);
	}

	// componentDidUpdate(prevProps) {
		
	// 	if (prevProps.lastMessage !== this.props.lastMessage && this.props.lastMessage) {
	// 		console.log("these messages are differents...");
	// 		this.setState({ messages: [...this.state.messages, this.props.lastMessage] });
	// 		// this.findMessages(this.props.messageToken);
	// 	}
	// 	this.scrollToBottom();
	// }

	// getChats = () => {
	// 	let data = {
	// 		nature: 'PRIVATE',
	// 		activeChatId: this.props.currentChat ? this.props.currentChat.id : 0
	// 	}
	// 	ChatService.getChats(data).then(response => {
	// 		this.props.setChats(response);
	// 	});
	// }

	findMessages = (token) => {
		ChatService.getMessages(token).then((response) => {
			if(response.length > this.state.messages.length) {
				this.setState({ messages: response.reverse() }, () => {
					this.scrollToBottom();
				});	
			}
		})
	}

	getMsgType = obj => {
		if (obj.content) {
			return <span>{obj.content}</span>
		} else {
			return (
				<Flex alignItems="center" className="msg-file">
					<FileOutlined className="font-size-md" />
					<span className="ml-2 font-weight-semibold text-link pointer">
						<u>{obj.text}</u>
					</span>
				</Flex>
			)
		}

	}

	scrollToBottom = () => {
		this.chatBodyRef.current.scrollToBottom();
	}

	onSend = (values) => {
		if (values.newMsg) {
			let datas = {
				file: "",
				content: values.newMsg,
				senderId: (this.props.user?.id) ? this.props.user?.id : 0,
				topic: this.props.token
			}
			ChatService.sendPrivateSms(datas).then((message) => {
				// this.setState({ messages: [...this.state.messages, message] });
				this.formRef.current.setFieldsValue({
					newMsg: ''
				});
			}).catch(err => {
				console.log(err);
			}).finally(() => {
			});
		}
	};

	emptyClick = (e) => {
		e.preventDefault();
	};

	chatContentHeader = (name, avatar) => {
		return (
			<div className="chat-content-header">
				<div>
					<AvatarStatus 
						src={Utils.getFilePath(avatar)} 
						name={name} 
					/>
				</div>
				<div>
					<EllipsisDropdown menu={menu(this.props)} />
				</div>
			</div>
		)
	}

	chatContentBody = (messages, id) => {
		// console.log(this.props.senderType);
		return (
			<div className="chat-content-body">
				<Scrollbars ref={this.chatBodyRef} autoHide>
					{
						messages.reduce((unique, o) => {if(!unique.some(obj => obj.id === o.id)) {unique.push(o);}return unique;},[]).map((elm, i) => (
							<div
								key={`msg-${id}-${i}`}
								className={`msg ${elm.msgType === 'date' ? 'datetime' : ''} ${elm.senderData?.senderType === this.props?.senderType ? 'msg-sent' : 'msg-recipient'}`}
							>
								{
									elm.senderData?.avatar ?
										<div className="mr-2">
											<Avatar src={Utils.getFilePath(elm.senderData?.avatar)} />
										</div>
										:
										null
								}
								{
									elm.content ?
										<div className={`bubble ${!elm.senderData.avatar ? 'ml-5' : ''}`}>
											<div className="bubble-wrapper">
												{this.getMsgType(elm)}
											</div>
										</div>
										:
										null
								}
								{
									elm.msgType === 'date' ?
										<Divider>{elm.time}</Divider>
										:
										null
								}
							</div>
						))
					}
				</Scrollbars>
			</div>
		)
	}

	chatContentFooter = () => (
		<div className="chat-content-footer">
			<Form name="msgInput" ref={this.formRef} onFinish={this.onSend} className="w-100">
				<Form.Item name="newMsg" className="mb-0">
					<Input
						autoComplete="off"
						placeholder={this.props.intl.formatMessage({id: 'component.typeMessage'})}
						suffix={
							<div className="d-flex align-items-center">
								{/* <a href="/#" className="text-dark font-size-lg mr-3">
									<PaperClipOutlined />
								</a> */}
								<Button shape="circle" type="primary" size="small" onClick={this.onSend} htmlType="submit">
									<SendOutlined />
								</Button>
							</div>
						}
					/>
				</Form.Item>
			</Form>
		</div>
	)


	render() {
		const { info, messages } = this.state

		return (
			<div className="chat-content">
				{this.chatContentHeader((!this.props.user?.id) ? this.props.meeting.user?.fullName : this.props.meeting.contact?.name, (!this.props.user?.id) ? this.props.meeting.user?.avatar : this.props.meeting.contact?.avatar)}
				{this.chatContentBody(messages, info?.id)}
				{this.chatContentFooter()}
			</div>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { user }
}

export default connect(mapStateToProps, {})(injectIntl(Conversation));
