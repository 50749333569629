import { env } from './EnvironmentConfig';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';

export const APP_NAME = 'Immersively Care';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const ROOT = '/';
export const AUTH_PREFIX_PATH = '/auth';
export const ADMIN_PREFIX_PATH = '/admin';
export const ADMIN_ROLE = 'ADMIN';
export const USER_ROLE = 'USER';
export const WEB_SOCKET_URL = API_BASE_URL + '/ws-chat/';
export const OAUTH = {
    clientId: 'beinx-app',
    clientSecret: 'beinx-secret',
    grantType: 'password',
};
export const MIXPANEL_TOKEN = "d4e4e7651ee267e9091a795bd31f1821";
export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
};