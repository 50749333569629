import React from 'react'
import { injectIntl } from 'react-intl';
import Conversation from './Conversation';

const ChatContent = (props) => {
  return (
    <div>
      <Conversation {...props} />

    </div>

  )
}
export default injectIntl(ChatContent);
