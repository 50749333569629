import * as FrontUrl from '../constants/FrontendUrl';
import {
  DashboardOutlined, UserOutlined, TeamOutlined,
ShoppingOutlined, MessageOutlined, TagsOutlined, DotChartOutlined, 
VideoCameraOutlined, CalendarOutlined, BarsOutlined, PlusCircleTwoTone,
VideoCameraTwoTone
} from '@ant-design/icons';

const userDashBoardNavTree = [{
  key: 'dashboards',
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  role: 'USER',
  submenu: [
    {
      key: 'dashboards-start-session',
      title: 'sidenav.pages.startSession',
      actionType: 'START_SESSION',
      icon: VideoCameraTwoTone,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-create-session',
      path: FrontUrl.SESSION.CREATE,
      title: 'sidenav.pages.createSession',
      icon: PlusCircleTwoTone,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'dashboards-list-session',
      path: FrontUrl.SESSION.SELF,
      title: 'sidenav.pages.sessions',
      icon: BarsOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'dashboards-calendar-session',
      path: FrontUrl.SESSION.CALENDAR,
      title: 'sidenav.pages.calendar',
      icon: CalendarOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'dashboards-contact',
      path: FrontUrl.CONTACT.SELF,
      title: 'sidenav.apps.contact',
      icon: UserOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'dashboards-profile',
      path: FrontUrl.PROFILE.SELF,
      title: 'sidenav.apps.myactivity',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-subscriptions-management',
      title: 'sidenav.apps.subscription',
      icon: TagsOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'dashboards-active-plan',
          path: FrontUrl.ACTIVE_PLAN,
          title: 'sidenav.apps.activePlan',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-subscriptions',
          path: FrontUrl.SUBSCRIPTIONS.SELF,
          title: 'sidenav.apps.mySubscriptions',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-transactions',
          path: FrontUrl.TRANSACTIONS.SELF,
          title: 'sidenav.apps.transactions',
          breadcrumb: true,
          submenu: []
        }
      ]
    }
  ]
}];

const adminDashBoardTree = [{
  key: 'dashboards',
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  role: 'ADMIN',
  submenu: [
    {
      key: 'dashboards-users',
      title: 'sidenav.apps.users',
      icon: TeamOutlined,
      breadcrumb: false,
      path: FrontUrl.USERS.LIST,
      submenu: []
    },
    {
      key: 'dashboards-products',
      title: 'sidenav.apps.products',
      icon: ShoppingOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'dashboards-sub-categories-self',
          path: FrontUrl.INTEREST.LIST,
          title: 'sidenav.pages.subCategories',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-products-categories',
          path: FrontUrl.INTEREST.TYPE.LIST,
          title: 'sidenav.pages.categories',
          breadcrumb: true,
          submenu: []
        }
      ]
    },
    {
      key: 'dashboards-chat',
      path: FrontUrl.SUBSCRIPTIONS,
      title: 'sidenav.apps.communication',
      icon: MessageOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'dashboards-create-chat',
          path: FrontUrl.ADMIN_CHAT.CREATE,
          title: 'sidenav.pages.createChat',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-private-chat',
          path: FrontUrl.ADMIN_CHAT.PRIVATE,
          title: 'sidenav.pages.headquarterToMember',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-topic-chat',
          path: FrontUrl.ADMIN_CHAT.TOPIC,
          title: 'sidenav.pages.topicRoom',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'dashboards-group-chat',
          path: FrontUrl.ADMIN_CHAT.GROUP,
          title: 'sidenav.pages.chatRoom',
          breadcrumb: true,
          submenu: []
        },
      ]
    }
  ]
}];

const navigationConfig = [
  ...userDashBoardNavTree,
  ...adminDashBoardTree
]

export default navigationConfig;
