import * as Routes from "./routes";
import fetch from '../../auth/FetchInterceptor';

export default class SessionService {

    static getUserSessions() {
        return fetch({
            url: Routes.GET_USER_SESSION,
            method: 'get'
        })
    };

    static getPreviousUserSessions() {
        return fetch({
            url: Routes.GET_PREVIOUS_USER_SESSION,
            method: 'get'
        })
    };

    static getTimezones() {
        return fetch({
            url: Routes.GET_TIMEZONE,
            method: 'get'
        })
    };

    static getUpcomingUserSessions() {
        return fetch({
            url: Routes.GET_UPCOMING_USER_SESSION,
            method: 'get'
        })
    };

    static getMeetingSignals(topic) {
        return fetch({
            url: Routes.FIND_SESSION_DATAS(topic),
            method: 'get'
        })
    };

    static getMeetingRecord(reference) {
        return fetch({
            url: Routes.FIND_RECORD(reference),
            method: 'get'
        })
    };

    static createSession(data) {
        return fetch({
            url: Routes.CREATE_SESSION,
            method: 'post',
            data
        })
    };

    static upadteSession(data, id) {
        return fetch({
            url: Routes.UPDATE_SESSION(id),
            method: 'put',
            data
        })
    };

    static getRecords(topic) {
        return fetch({
            url: Routes.GET_SESSION_RECORD(topic),
            method: 'get'
        })
    };

    static streamVideo(reference) {
        return fetch({
            url: Routes.STREAM_VIDEO(reference),
            method: 'get'
        })
    };

    static findSession(id) {
        return fetch({
            url: Routes.FIND_SESSION(id),
            method: 'get',
        })
    };

    static findSessionInPublic(topic) {
        return fetch({
            url: Routes.FIND_SESSION_PUBLIC(topic),
            method: 'get',
        })
    };

    static findSessionTranscriptions(topic) {
        return fetch({
            url: Routes.FIND_TRANSCRIPTION(topic),
            method: 'get',
        })
    };

    static deleteSession(id) {
        return fetch({
            url: Routes.DELETE_SESSION(id),
            method: 'delete',
        })
    };
}