export const GET_USER_SESSION = 'api/sessions/user';
export const GET_TIMEZONE = 'api/sessions/timezone';
export const CREATE_SESSION = 'api/sessions/schedule';
export const FIND_SESSION = (id) => `api/sessions/${id}`;
export const DELETE_SESSION = (id) => `api/sessions/${id}`;
export const UPDATE_SESSION = (id) => `api/sessions/${id}`;
export const GET_PREVIOUS_USER_SESSION = 'api/sessions/previous';
export const GET_UPCOMING_USER_SESSION = 'api/sessions/upcoming';
export const STREAM_VIDEO = (reference) => `api/stream/${reference}`;
export const FIND_SESSION_PUBLIC = (topic) => `api/sessions/public/${topic}`;
export const GET_SESSION_RECORD = (topic) => `api/sessions/records/${topic}`;
export const FIND_SESSION_DATAS = (topic) => `api/sessions/datas/live/${topic}`;
export const FIND_RECORD = (reference) => `api/sessions/records/find/${reference}`;
export const FIND_TRANSCRIPTION = (reference) => `api/sessions/transcription/live/${reference}`;