import React, { lazy, Suspense } from "react";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import * as FrontUrl from '../../constants/FrontendUrl';
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={FrontUrl.HOME} component={lazy(() => import(`./home`))} />
        <Route path={FrontUrl.CONTACT.SELF} component={lazy(() => import(`./contact`))}/>
        <Route path={FrontUrl.PROFILE.SELF} component={lazy(() => import(`./profile`))} />
        <Route path={FrontUrl.ACTIVE_PLAN} component={lazy(() => import(`./active-plan`))}/>
        <Route path={FrontUrl.TRANSACTIONS.SELF} component={lazy(() => import(`./transactions`))}/>
        <Route path={FrontUrl.SUBSCRIPTIONS.SELF} component={lazy(() => import(`./subscriptions`))}/>
        <Route path={FrontUrl.SESSION.SELF} component={lazy(() => import(`./session-mangement`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={FrontUrl.HOME} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);