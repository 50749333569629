import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
import ChatContent from './ChatContent';
import ChatService from 'services/chat';
import { WEB_SOCKET_URL } from 'configs/AppConfig';
import InnerAppLayout from 'layouts/inner-app-layout';
import SessionService from 'services/sessions';

const Chat = (props) => {


	const [session, setSession] = useState({});
	const [lastMessage, setLastMessage] = useState(null);
	const [messages, setMessages] = useState([]);
	const [senderType, setSenderType] = useState('');
	const messageToken = (Object.fromEntries(new URLSearchParams(window.location.search)).q) ? Object.fromEntries(new URLSearchParams(window.location.search)).q : null;
	useEffect(() => {
		if (messageToken != null) {
			SessionService.findSessionInPublic(messageToken).then((response) => {
				setSession(response);
			});
			ChatService.getMessages(messageToken).then((response) => {
				setMessages(response);	
			})
			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps	
	}, [messageToken]);

	useEffect(() => {
		// console.log(props?.user);
		if (props?.user !== null) {
			setSenderType('USER')
		} else {
			setSenderType('CONTACT')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps	
	},[props?.user])
	const onMessageReceived = (message) => {
		//Checking if user need to get the new message
		message = JSON.parse(message.content);
		//console.log(message);

		if (message.token === messageToken) {
			if(message.chatMemberType !== senderType) {
				setLastMessage(message);
			}
		} 

	}
	return (
		<div className="chat">
			<InnerAppLayout
				mainContent={<ChatContent {...props} lastMessage={lastMessage} meeting={session} token={messageToken} messages={messages} senderType={senderType}/>}
				sideContentWidth={450}
				sideContentGutter={false}
				border
			/>

			<SockJsClient
				debug={true}
				url={WEB_SOCKET_URL}
				topics={['/topic/group']}
				onDisconnect={console.log("Disconnected!")}
				onConnect={console.log("Connected!")}
				onMessage={msg => onMessageReceived(msg)}
			/>
		</div>
	)
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { user }
}

export default connect(mapStateToProps, {})(Chat);
