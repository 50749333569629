import React, { lazy, Suspense } from "react";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import * as FrontUrl from '../../constants/FrontendUrl';
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AdminViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={FrontUrl.USERS.SELF} component={lazy(() => import(`./users`))} />
        <Route path={FrontUrl.PRODUCT.SELF} component={lazy(() => import(`./products`))} />
        <Route path={FrontUrl.INTEREST.SELF} component={lazy(() => import(`./interests`))} />
        <Route path={FrontUrl.ADMIN_PURCHASE_REQUEST.SELF} component={lazy(() => import(`./requests`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={FrontUrl.USERS.SELF} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AdminViews);