import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, ADMIN_PREFIX_PATH } from '../configs/AppConfig';

export const HOME = `${APP_PREFIX_PATH}/home`;
export const ACTIVE_PLAN = `${APP_PREFIX_PATH}/active-plan`;
export const LIVE_CHAT = `/chat`;
export const MAIN_DOMAIN = `https://immersively.care`;

export const LANDING = {
    ROOT: `/dashboard`,
    HOME: `/dashboard/home`,
    CONTACT: `/dashboard/contact`,
    PHILOSOPHY: `/dashboard/philosophy`
}

export const CONTACT= {
    SELF: `${APP_PREFIX_PATH}/contacts`,
    LIST: `${APP_PREFIX_PATH}/contacts/list`,
    CREATE: `${APP_PREFIX_PATH}/contacts/create`,
    IMPORT: `${APP_PREFIX_PATH}/contacts/import`,
    UPDATE: `${APP_PREFIX_PATH}/contacts/:id/update`
}

export const SESSION= {
    SELF: `${APP_PREFIX_PATH}/sessions`,
    LIST: `${APP_PREFIX_PATH}/sessions/list`,
    START: `${APP_PREFIX_PATH}/sessions/start`,
    CREATE: `${APP_PREFIX_PATH}/sessions/create`,
    CALENDAR: `${APP_PREFIX_PATH}/sessions/calendar`,
    UPDATE: `${APP_PREFIX_PATH}/sessions/:id/update`,   
    DETAILS: `${APP_PREFIX_PATH}/sessions/:id/details`,
    RECORDS: `${APP_PREFIX_PATH}/sessions/:topic/records`,
    PLAYER: `${APP_PREFIX_PATH}/sessions/:topic/records/:reference`
}
export const VIDEO = {
    SELF: '/session',
    HOME: '/app/session-start',
    CALL: '/session-call'
};

export const PROFILE = {
    SELF: `${APP_PREFIX_PATH}/profile`,
    VIEW: `${APP_PREFIX_PATH}/profile/view`,
    UPDATE: `${APP_PREFIX_PATH}/profile/update`,
}

export const AUTH = {
    LOGIN: `${AUTH_PREFIX_PATH}/login`,
    REGISTRATION: `${AUTH_PREFIX_PATH}/register`,
    FORGOT_PASSWORD: `${AUTH_PREFIX_PATH}/forgot-password`,
    RESET_PASSWORD: `${AUTH_PREFIX_PATH}/reset-password`,
    THANK_YOU: `${AUTH_PREFIX_PATH}/thank-you`,
}

export const INTEREST = {
    SELF: `${ADMIN_PREFIX_PATH}/interests`,
    LIST: `${ADMIN_PREFIX_PATH}/interests/list`,
    CREATE: `${ADMIN_PREFIX_PATH}/interests/create`,
    UPDATE: `${ADMIN_PREFIX_PATH}/interests/:id/update`,    
    TYPE: {
        SELF: `${ADMIN_PREFIX_PATH}/interests/types`,
        LIST: `${ADMIN_PREFIX_PATH}/interests/types/list`,
        CREATE: `${ADMIN_PREFIX_PATH}/interests/types/create`,
        UPDATE: `${ADMIN_PREFIX_PATH}/interests/types/:id/update`,    
    }
}

export const PRODUCT = {
    SELF: `${ADMIN_PREFIX_PATH}/products`,
    ATTRIBUTE: {
        SELF: `${ADMIN_PREFIX_PATH}/products/attributes`,
        LIST: `${ADMIN_PREFIX_PATH}/products/attributes/list`,
        CREATE: `${ADMIN_PREFIX_PATH}/products/attributes/create`,
        UPDATE: `${ADMIN_PREFIX_PATH}/products/attributes/:id/update`,    
    },
    TYPE: {
        SELF: `${ADMIN_PREFIX_PATH}/products/types`,
        LIST: `${ADMIN_PREFIX_PATH}/products/types/list`,
        CREATE: `${ADMIN_PREFIX_PATH}/products/types/create`,
        UPDATE: `${ADMIN_PREFIX_PATH}/products/types/:id/update`,    
    },
    CATEGORY: {
        SELF: `${ADMIN_PREFIX_PATH}/products/categories`,
        LIST: `${ADMIN_PREFIX_PATH}/products/categories/list`,
        CREATE: `${ADMIN_PREFIX_PATH}/products/categories/create`,
        UPDATE: `${ADMIN_PREFIX_PATH}/products/categories/:id/update`,    
    },
}

export const USERS = {
    SELF: `${ADMIN_PREFIX_PATH}/users`,
    LIST: `${ADMIN_PREFIX_PATH}/users/list`
}

export const ADMIN_PURCHASE_REQUEST = {
    SELF: `${ADMIN_PREFIX_PATH}/contacts`,
    LIST: `${ADMIN_PREFIX_PATH}/contacts/list`,
    DETAILS: `${ADMIN_PREFIX_PATH}/contacts/:id/details`,
    UPDATE: `${ADMIN_PREFIX_PATH}/contacts/:id/update`,
}

export const ADMIN_CHAT = {
    SELF: `${ADMIN_PREFIX_PATH}/chat`,
    CREATE: `${ADMIN_PREFIX_PATH}/chat/create`,
    PRIVATE: `${ADMIN_PREFIX_PATH}/chat/private`,
    TOPIC: `${ADMIN_PREFIX_PATH}/chat/topic`,
    GROUP: `${ADMIN_PREFIX_PATH}/chat/group`,
}

export const SUBSCRIPTIONS = {
    SELF: `${APP_PREFIX_PATH}/subscriptions`,
    PRIVATE: `${APP_PREFIX_PATH}/subscriptions/private`,
    TOPIC: `${APP_PREFIX_PATH}/subscriptions/topic`,
    GROUP: `${APP_PREFIX_PATH}/subscriptions/group`,
    CREATE: `${APP_PREFIX_PATH}/subscriptions/create`,
}

export const TRANSACTIONS = {
    SELF: `${APP_PREFIX_PATH}/transactions`,
    PRIVATE: `${APP_PREFIX_PATH}/transactions/private`,
    TOPIC: `${APP_PREFIX_PATH}/transactions/topic`,
    GROUP: `${APP_PREFIX_PATH}/transactions/group`,
    CREATE: `${APP_PREFIX_PATH}/transactions/create`,
}
