import * as Routes from "./routes";
import fetch from '../../auth/FetchInterceptor';

export default class ChatService {

    static sendPrivateSms(data) {
        return fetch({
            url: Routes.SEND_PRIVATE_MESSAGE,
            method: 'post',
            data
        })
    };

    static getMessages(token) {
        return fetch({
            url: Routes.GET_MESSAGES_BY_TOKEN(token),
            method: 'get',
        })
    };

    static findMessages(id, params) {
        return fetch({
            url: Routes.FIND_MESSAGE(id ? id : 0),
            method: 'get',
            params
        })
    };

    static createChat(data) {
        return fetch({
            url: Routes.CREATE_CHAT,
            method: 'post',
            data
        })
    };
}