import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Chat from './Chat';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    chat: Chat
});

export default reducers;