import { RESET_CHAT, SET_CHAT, SET_CHATS, ADD_CHAT } from 'redux/constants/Chat'

const initState = {
    currentChat: null,
	chats: []
}

const chat = (state = initState, action) => {
	switch (action.type) {
		case SET_CHAT:
			return {
				...state,
				currentChat: action.chat
			}
		case SET_CHATS:
			return {
				...state,
				chats: action.chats
			}
		case ADD_CHAT:
			return {
				...state,
				chats: [...state.chats, action.chat]
			}
		case RESET_CHAT:
			return {
				...state,
				currentChat: null
			}
		default:
			return state;
	}
}

export default chat