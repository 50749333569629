import React from "react";
import Utils from "utils";
import { useHistory } from 'react-router-dom';
import { USER_ROLE } from "configs/AppConfig";
import { Menu, Dropdown, Avatar } from "antd";
import { PROFILE } from "constants/FrontendUrl";
import Icon from 'components/util-components/Icon';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import IntlMessage from "components/util-components/IntlMessage";
import mixpanel from "mixpanel-browser";

const menuItem = [
  {
    role: USER_ROLE,
    icon: EditOutlined,
    title: "button.editProfile",
    path: PROFILE.UPDATE,
  },
]

export const NavProfile = (props) => {

  const history = useHistory();
  const profileImg = Utils.getFilePath(props.user?.avatar);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div>
            <Avatar size={30} src={profileImg} />
          </div>
          <div className="pl-2">
            <h4 className="mb-0">{props.user?.fullName}</h4>
            <span className="text-muted">{props.user?.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.filter(menu => menu.role === props.user?.role.toUpperCase()).map((el, i) => {
            return (
              <Menu.Item key={i}>
                {/* eslint-disable-next-line */}
                <a onClick={() => {
                  history.push(el.path);
                  mixpanel.track("Clicked on Edit my profile");
                }
                }>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal"><IntlMessage id={el.title} /></span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={() => {
            props.signOut();
            mixpanel.track("Clicked on Sign out");
          }}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal"><IntlMessage id={'button.signOut'} /></span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default NavProfile;
