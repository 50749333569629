import fetch from '../../auth/FetchInterceptor';
import * as UserRoutes from '../../services/users/routes';
import { AUTH_PREFIX_PATH } from '../../configs/AppConfig';
import { AUTH, LIVE_CHAT, MAIN_DOMAIN } from '../../constants/FrontendUrl';
import { AUTH_TOKEN, AUTH_USER, SIGNOUT } from '../constants/Auth';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { showAuthMessage, loginUserSuccess } from "../actions/Auth";

export function* watchSetAuthUser() {
	yield takeEvery(AUTH_USER, setAuthUser);
}

const getAuthData = async () => {
	return await fetch.get(UserRoutes.KYC);
};

function* setAuthUser({ callback }) {
	try {
		const authUserResponse = yield call(getAuthData);
		yield put(loginUserSuccess(authUserResponse));
	} catch (e) {
		if(!window.location.href.includes(LIVE_CHAT) && 
			!window.location.href.includes(AUTH_PREFIX_PATH))
			window.location.replace("/dashboard"+AUTH.LOGIN+window.location.search);
	} finally {
	  if (callback) callback();
	}
  }

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.removeItem(AUTH_TOKEN);
			if(!window.location.href.includes(LIVE_CHAT) && 
				!window.location.href.includes(AUTH_PREFIX_PATH))
				window.location.replace(MAIN_DOMAIN+"/session-thankyou/");
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signOut),
		fork(watchSetAuthUser),
  ]);
}
